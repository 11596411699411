import Nav from 'react-bootstrap/Nav';
import styles from './Nav.module.css'
import { Link } from 'react-router-dom';

function InicioNav() {
  return (
    <Nav
    
      activeKey="/home"
      style={{ paddingTop: "3dvh", backgroundColor: "#98a6ad", display: "flex", justifyContent: "center" }}
    >
      <Nav.Item>
        <Link to="#1" className={styles.navLink} > Diseño Responsivo     </Link>
      </Nav.Item>
      <Nav.Item>
      <Link to="#2" className={styles.navLink} > Diseño web     </Link>
      </Nav.Item>
      <Nav.Item>
      <Link to="#3" className={styles.navLink} > Experiencia de usuario     </Link>
      </Nav.Item>
      <Nav.Item>
      <Link to="#4" className={styles.navLink} > eCommerce     </Link>
      </Nav.Item>
      <Nav.Item>
      <Link to="#5" className={styles.navLink} > SEO     </Link>
      </Nav.Item>
      <Nav.Item>
      <Link to="#6" className={styles.navLink} > BackEnd     </Link>
      </Nav.Item>
      

    </Nav>
  );
}

export default InicioNav;