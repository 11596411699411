import React from 'react'
import styles from './Curriculum.module.css'
import styles2 from './PastArrange.module.css'
import { BsLinkedin } from 'react-icons/bs'
import { AiFillPhone } from 'react-icons/ai'
import { GrMail } from 'react-icons/gr'

function Curriculum() {

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth <= 768;


  return (


    isSmallScreen ?

      //Small screen
      <div className={styles.Section}>
        <div className={styles.container}>
          <div className={styles.left}>
            <h1 style={{ fontSize: "6vw", marginTop: "15px" }}>Dev. Ricardo Gonzalez Gonzalez</h1>
            <h3 style={{ fontSize: "3vw" }}>Full Stack Developer</h3>
            <h6><GrMail />ricardo88glz@gmail.com</h6>
            <h6><AiFillPhone />+52 871 458 2803</h6>
            <h6 style={{ marginBottom: "10vh" }}><BsLinkedin /> ricardo-gonzalez-gonzalez-6a86b9185/</h6>
            <img className={styles.cv} src='./images/cv3.jpg' alt="" />
            <h3 style={{ marginTop: "5vh" }}>Experiencia Laboral</h3>
            <ul style={{ marginTop: "5vh" }}>
              <li>
                <p className={styles.t1}>BrightChamps</p>
                <p className={styles.t2}>Maestro de programación</p>
                <p className={styles.t3}>Enero 2023 - Actual</p>
              </li>
              <li>
                <p className={styles.t1}>MCI Mining</p>
                <p className={styles.t2}>Gerente de tecnología</p>
                <p className={styles.t3}>Marzo 2022 - Actual</p>
              </li>
              <li>
                <p className={styles.t1}>TEYSA Biomédica Grupo SEL</p>
                <p className={styles.t2}>Asesor Biomédico</p>
                <p className={styles.t3}>Mayo 2021 - Marzo 2022</p>
              </li>
              <li>
                <p className={styles.t1}>Grupo SEL</p>
                <p className={styles.t2}>Ingeniero de servicio</p>
                <p className={styles.t3}>Enero 2021 - Mayo 2021</p>
              </li>
            </ul>
            <h3 style={{ marginTop: "5vh" }}>Educación</h3>
            <ul style={{ marginTop: "5vh" }}>
              <li>
                <p className={styles.t1}>Ing. Biomédico</p>
                <p className={styles.t2}>Universidad LaSalle Laguna</p>
                <p className={styles.t3}>Agosto 2016 - Diciembre 2020</p>
              </li>
              <li>
                <p className={styles.t1}>Prescoler - Preparatoria</p>
                <p className={styles.t2}>Escuela Carlos Pereyra</p>
                <p className={styles.t3}>Agosto 2000 - Junio 2015</p>
              </li>
            </ul>
          </div>
          <div className={styles.center}>
            <h2 style={{ fontSize: "4vw" }}>dotDev</h2>
            <p className={styles.hab1}>dotDev, mi empresa, está especializada en el desarrollo de aplicaciones web y páginas web, abarcando desde sitios informativos hasta aplicaciones como comercio electrónico. Esta experiencia ha sido un logro significativo en mi trayectoria profesional como programador, ya que ha sido la manera en la que he podido dar mis primeros pasos en el campo.</p>
            <h2 style={{ marginTop: "10vh", fontSize: "4vw" }}>Habilidades</h2>
            <h4 className={styles.h4}>Bases de datos</h4>
            <p className={styles.hab}>Con mi conocimiento en bases de datos SQL y NoSQL, tengo la capacidad de desarrollar aplicaciones que permiten un acceso eficiente a la información almacenada, garantizando la integridad y seguridad de los datos.</p>
            <h4 className={styles2.h4}>Integración</h4>
            <p className={styles2.hab}>Tengo experiencia en la integración de sistemas, aplicaciones y componentes para asegurar un flujo eficiente de información y procesos. Puedo diseñar interfaces y APIs que facilitan la comunicación y transferencia de datos entre sistemas diferentes.</p>

            <h4 className={styles2.h4}>FrontEnd</h4>
            <p className={styles2.hab}>El front-end de una aplicación web es la primera interacción que tiene un usuario. Con mi conocimiento en html, css y mi experiencia en con js en react, el diseño y desarrollo de aplicaciones se vuelven sencillos y eficaces.</p>

            <h4 className={styles2.h4}>BackEnd</h4>
            <p className={styles2.hab}>Con experiencia en desarrollo backend utilizando Node.js, puedo construir servidores y aplicaciones escalables. Utilizo frameworks como Express.js y tengo habilidades en la gestión de bases de datos. Además, puedo crear APIs RESTful. Comprometido con la calidad del código, garantizo la legibilidad y mantenibilidad del software backend.</p>

            <h4 className={styles2.h4}>Autenticación</h4>
            <p className={styles2.hab}>Experiencia en autenticación de sistemas, incluyendo métodos como tokens JWT y protocolos como OAuth. Integración de sistemas de gestión de identidad y acceso para una autenticación segura y autorización en aplicaciones y servicioss </p>
          </div>
          <div className={styles.right} >
            <img src="./dotDev.svg" alt="" style={{ height: "30vh", width: "30vh", marginTop: "10vh" }} />
            <h2 className={styles.h2} style={{ fontSize: "4vw" }}>Valores</h2>
            <ul>
              <li>Resiliente</li>
              <li>Perseverante</li>
              <li>Autodidacta</li>
              <li>Toma de decisiones</li>
              <li>Liderazgo</li>
            </ul>
            <h2 className={styles.h2} style={{ fontSize: "4vw" }}>Fortalezas</h2>
            <ul>
              <li>Adaptabilidad</li>
              <li>Programación</li>
              <li>Comunicación</li>
              <li>Trabajo en equipo</li>
              <li>Gestión de proyectos</li>
            </ul>
            <h2 className={styles.h2} style={{ fontSize: "4vw" }}>Idiomas</h2>
            <ul>
              <li>Español Nativo</li>
              <li style={{ marginBottom: "10dvh" }}>Inglés Avanzado</li>
            </ul>
          </div>
        </div>
      </div>
      :
      //bigScreen

      <div className={styles2.Section}>
        <div className={styles2.container}>




          <div className={styles2.left}>
            <img className={styles2.cv} src='./images/cv3.jpg' alt="" />
            <h3 style={{ marginTop: "5dvh" }}>Experiencia Laboral</h3>
            <ul style={{ marginTop: "5dvh" }}>

              <li>
                <p className={styles2.t1}>BrightChamps</p>
                <p className={styles2.t2}>Maestro de programación</p>
                <p className={styles2.t3}>Enero 2023 - Mayo 2023</p>
              </li>
              <li>
                <p className={styles2.t1}>MCI Mining</p>
                <p className={styles2.t2}>Gerente de tecnología</p>
                <p className={styles2.t3}>Marzo 2022 - Actual</p>
              </li>
              <li>
                <p className={styles2.t1}>TEYSA Biomédica Grupo SEL</p>
                <p className={styles2.t2}>Asesor Biomédico</p>
                <p className={styles2.t3}>Mayo 2021 - Marzo 2022</p>
              </li>
              <li>
                <p className={styles2.t1}>Grupo SEL</p>
                <p className={styles2.t2}>Ingeniero de servicio</p>
                <p className={styles2.t3}>Enero 2021 - Mayo 2021</p>
              </li>
            </ul>
            <h3 style={{ marginTop: "5dvh" }}>Educación</h3>
            <ul style={{ marginTop: "5dvh" }}>
              <li>
                <p className={styles2.t1}>Ing. Biomédico</p>
                <p className={styles2.t2}>Universidad LaSalle Laguna</p>
                <p className={styles2.t3}>Agosto 2016 - Diciembre 2020</p>
              </li>
              <li>
                <p className={styles2.t1}>Preparatoria</p>
                <p className={styles2.t2}>Escuela Carlos Pereyra</p>
                <p className={styles2.t3}>Agosto 2012 - Junio 2015</p>
              </li>

            </ul>
          </div>




          <div className={styles2.center}>
            <h1>Dev. Ricardo Gonzalez Gonzalez</h1>
            <h3>Full Stack Developer</h3>
            <h6><GrMail />ricardo88glz@gmail.com</h6>
            <h6><AiFillPhone />+52 871 458 2803</h6>
            <h6 style={{ marginBottom: "10dvh" }}><BsLinkedin /> ricardo-gonzalez-gonzalez-6a86b9185/</h6>
            <h2 style={{ marginTop: "10dvh" }}>Habilidades</h2>
            <ul>
            <li>Python - Intermedio Avanzado</li>
            <li>JavaScript - Intermedio Avanzado</li>
            <li>HTML - Intermedio Avanzado</li>
            <li>CSS - Intermedio Avanzado</li>
            <li>React - Básico Intermedio</li>
            <li>MongoDB - Básico</li>
            <li>Express - Básico</li>
            <li>Node - Básico</li>
            <li>Git - Básico</li>
            <li>GitHub - Básico</li>

            </ul>

          </div>






          <div className={styles2.right}>
            <img src="./dotDev.svg" alt="" />
            <h2 className={styles2.h2}>Valores</h2>
            <ul>
              <li>Resiliente</li>
              <li>Perseverante</li>
              <li>Autodidacta</li>
              <li>Toma de decisiones</li>
              <li>Liderazgo</li>
            </ul>

            <h2 className={styles2.h2}>Fortalezas</h2>
            <ul>
              <li>Adaptabilidad</li>
              <li>Programación</li>
              <li>Comunicación</li>
              <li>Trabajo en equipo</li>
              <li>Gestión de proyectos</li>
            </ul>
            <h2 className={styles2.h2}>Idiomas</h2>
            <ul>
              <li>Español Nativo</li>
              <li>Inglés Avanzado</li>
            </ul>
          </div>
        </div>
      </div>
  )
}


export default Curriculum