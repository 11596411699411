import React from 'react';
import styles from './Blog.module.css';
import classNames from 'classnames';
import './Blog.module.css'

function Blog(props) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth <= 768;
  return (
    <div className={classNames(styles.Section)} >
      <div >
        <h1 style={{ textAlign: "center", marginTop: "5vh", fontSize: "5vw" }}>{props.title}</h1>

        <div className={styles.blog1} style={{ width: "90%", textAlign: isSmallScreen ? 'center' : null }}>

          <p style={{ width: "100%" }}>{props.body}</p>
        </div>

      </div>
      <div className={isSmallScreen ? styles.smallScreen : null} style={{display: "flex", justifyContent: "center"}}>
        <img src={props.image} alt="" className={styles.blogImg} />
      </div>
    </div>
  );
}

export default Blog;