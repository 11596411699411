import React from 'react'
import { Nav } from 'react-bootstrap'
import styles from '../Bootstrap/Nav/Nav.module.css'

function HomeNav(props) {
    return (
        <Nav variant="tabs" defaultActiveKey="/home" style={{ backgroundColor: "#98a6ad" }}>
            <Nav.Item>
                <Nav.Link className={styles.links} onClick={props.Biog}>Biografía</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className={styles.links} onClick={props.Cur} >Curriculum</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className={styles.links} onClick={props.Portaph} disabled >
                    Portafolio
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className={styles.links} onClick={props.Blog}>Blog</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default HomeNav
