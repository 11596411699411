import React from 'react'
import Cardette from './Dashboard2'
import styles from './Dashboard.module.css'
import { dashboardBody, dashboardTitle } from './dashboardData';


function Dashboard() {
    return (
        <div className={styles.pageContent}>
            <Cardette title={dashboardTitle[0]} text={dashboardBody[0]} image="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=872&q=80" />
            <Cardette title={dashboardTitle[1]} text={dashboardBody[1]} image="https://images.unsplash.com/photo-1545670723-196ed0954986?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=873&q=80" />
            <Cardette title={dashboardTitle[2]} text={dashboardBody[2]} image="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"/>
        </div>
    )
}

export default Dashboard
