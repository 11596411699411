import React from 'react'
import styles from './Biography.module.css'

function Biography() {
    return (
        <div className={styles.Section}>
            <div className={styles.paragh}>
                <h1 style={{fontSize:"5vw"}}>Ricardo González González</h1>
                <p style={{marginTop: "3dvh", textAlign:"justify"}}>¡Hola! Soy Ricardo, y este es mi sitio web donde presento algunas de mis habilidades como desarrollador full stack. Aunque mi formación es en ingeniería biomédica, mi pasión por la programación me ha impulsado a seguir una carrera en el desarrollo web de manera autodidacta. He adquirido conocimientos a través de cursos en línea, tutoriales en video y documentación, además de formar parte de comunidades de personas dedicadas que me han brindado valiosa ayuda para resolver dudas.

                    A traves de dotDev, una empresa cuyo enfoque es la creación de páginas y aplicaciones web como práctica, he desarrollado un número limitado de proyectos, estos trabajos me han brindado experiencia para seguir creciendo como desarrollador. Si estás en una situación similar y buscas orientación para encaminar tu futuro, no dudes en contactarme. Estoy aquí para ayudarte a construir el futuro que persigues.
                </p>
            </div>
        </div>
    )
}

export default Biography
