import React from 'react'
import PortafolioCard from './PortafolioCard'
import {tipo, titulo, descripcion} from './portafolioData'

function Portafolio() {
  return (
    <div>
      <PortafolioCard type={tipo[0]} title={titulo[0]} description={descripcion[0]} />
      <PortafolioCard type={tipo[1]} title="MCI Mining" description={descripcion[1]} />

    </div>
  )
}

export default Portafolio
