import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from './components/Hero';
import MyCarousel from './components/Bootstrap/Carrusel/Carrusel';
import BlogLists from './components/Blog/BlogList';
import { useState } from 'react';
import Curriculum from './components/Curriculum/Curriculum';
import Biography from './components/Biography/Biography';
import Portafolio from './components/Portafolio/Portafolio';
import HomeNav from './components/HomeNav/HomeNav';
import Dashboard from './components/Dashboard/Dashboard';


function App() {



  const [sectionRendered, setSectionRendered] = useState("Biog")
  function RenderBiog() {
    setSectionRendered('Biog')

  }
  function RenderBlogs() {
    setSectionRendered('BlogLists')

  }
  function RenderCurriculum() {
    setSectionRendered('Curriculum')

  }
  function RenderPort() {
    setSectionRendered('Portafolio')

  }

  return (
    <>
      <Hero />
      <Dashboard />
      <MyCarousel />
      <HomeNav Biog={RenderBiog} Blog={RenderBlogs} Cur={RenderCurriculum} Portaph={RenderPort} />

      {sectionRendered === 'Biog' ? <Biography /> : null}
      {sectionRendered === 'BlogLists' ? <BlogLists /> : null}
      {sectionRendered === 'Curriculum' ? <Curriculum /> : null}
      {sectionRendered === 'Portafolio' ? <Portafolio /> : null}

    </>
  )
}

export default App;
