import React from 'react';

function Hero() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth <= 768;

  return (
    <>
      <div className={`bg1 ${isSmallScreen ? 'small-screen' : 'large-screen'}`} style={{ height: isSmallScreen ? '40vh' : '70vh', display: 'flex', justifyContent: 'center' }}>
        <img src="dotDev.svg" alt="logo" style={{ height: isSmallScreen ? '20vh' : '40vh', position: 'sticky', top: isSmallScreen ? '5vh' : '10vh' }} />
      </div>
    </>
  );
}

export default Hero;