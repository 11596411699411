import React from 'react'
import Blog from './Blog'
import { blogBody, blogTitle } from './blogData'
import BlogNav from '../Bootstrap/Nav/BlogNav'


function BlogList() {

  return (
    <div>
      <BlogNav />
      <Blog
        id="1"
        title={blogTitle[0]}
        body={blogBody[0]}
        image="./images/blog-responsive.jpg"
        author="Ricardo Gonzalez"
      />
      <Blog
        id="2"
        title={blogTitle[1]}
        body={blogBody[1]}
        image="./images/blog-design.jpg"
        author="Ricardo Gonzalez"


      />
      <Blog
        title={blogTitle[2]}
        body={blogBody[2]}
        image="./images/blog-ux.jpg"
        author="Ricardo Gonzalez"


      />
      <Blog
        title={blogTitle[3]}
        body={blogBody[3]}
        image="./images/blog-ecom.jpg"
        author="Ricardo Gonzalez"

      />
      <Blog
        title={blogTitle[4]}
        body={blogBody[4]}
        image="./images/blog-seo.jpg"
        author="Ricardo Gonzalez"

      />
      <Blog
        title={blogTitle[5]}
        body={blogBody[5]}
        image="./images/blog-backend.jpg"
        author="Ricardo Gonzalez"

      />
    </div>
  )
}

export default BlogList
