export const tipo = ["Manage App", "Sitio Web", "eCommerce"]


export const titulo = ["The Nails Kitchen", "MCI Mining"]




export const descripcion = [
    "Esta aplicación web muestra la información de un spa de uñas, en el puedes hacer reservaciones y en su admin side puedes verlas.",
"Esta aplicación web muestra la información de un spa de uñas, en el puedes hacer reservaciones y en su admin side puedes verlas."
]