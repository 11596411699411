import React from 'react'
import styles from './Dashboard.module.css'

function Card(props) {
    return (
            <div className={styles.card} style={{backgroundImage: `url(${props.image})`, backgroundSize:"cover"}}>
                <div className={styles.content}>
                    <h2 className={styles.title}>{props.title}</h2>
                    <p className={styles.copy}>{props.text}</p>
                    <button className={styles.btn}>Solicitar</button>
                </div>
            </div>
    )
}

export default Card
