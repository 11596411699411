import React from 'react'
import Nav from '../Bootstrap/Nav/Nav'
import { Outlet } from 'react-router-dom'
import '../../index.css'

function RootLayout() {
    return (
        <>
            <Nav />
            <Outlet />
        </>
    )
}

export default RootLayout
