import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import styles from './ContactForm.module.css'

function ContactForm() {
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [texto, setTexto] = useState('');
    const navigate = useNavigate()

    const enviarFormulario = async function () {
        const data = {
            nombre,
            apellido,
            correo,
            telefono,
            texto,
        };

        try {
            const response = await fetch('http://localhost:3001/enviar', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            

            if (response.ok) {
                console.log('Formulario enviado exitosamente');
                navigate('/enviado')
                // Aquí puedes realizar cualquier acción adicional después de enviar el formulario
            } else {
                console.error('Error al enviar el formulario');
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        }
    };
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "3dvh" }}>
                <img src="dotDev.svg" alt="" />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", alignContent: "center", justifyContent: "center", width: "100%", height: "50dvh" }}>
                <Form>
                    <Row className='mb-3'>
                        <Col>
                            <Form.Control placeholder="Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </Col>
                        <Col>
                            <Form.Control placeholder="Apellido" value={apellido} onChange={(e) => setApellido(e.target.value)} />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <Form.Control placeholder='Mail'  value={correo} onChange={(e) => setCorreo(e.target.value)} />
                        </Col>
                        <Col>
                            <Form.Control placeholder='Teléfono' type='tel' value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Control placeholder='Dejanos tu mensaje' as="textarea" rows="4" value={texto} onChange={(e) => setTexto(e.target.value)} />
                    </Row>
                    <Row className='mb-3' style={{ justifyContent: "center" }}>
                        <button className={styles.button} onClick={enviarFormulario}>Enviar</button>

                    </Row>
                </Form>


            </div>
        </>
    )
}

export default ContactForm
