import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function MyCarousel() {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isSmallScreen = windowWidth <= 768;
    return (
        <div className='bg1' style={{ height: '110dvh', paddingBottom:"10%" }}>
            <div className='carou' style={{ width: "70dvw", paddingTop: "10dvh", paddingBottom:"10dvh" }}>
                <Carousel fade>
                    <Carousel.Item style={{border: "solid 7px #F0F0F0" }}>
                        <div style={{ backgroundColor: "rgba(0,0,0, 0.5", width: "100%", height: "30%", position: "absolute", left: "0", bottom: "0"}} />
                        <img
                            className='d-block w-100 carou '
                            src={isSmallScreen ? 'images/smallcar1.jpg' : 'images/restaurantcar.jpg' }
                            alt='First slide'
                        />
                        <Carousel.Caption>
                            <h3>Tu página web</h3>
                            <p>Atrae gente a tu negocio</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{border: "solid 7px #F0F0F0" }}>
                        <div style={{ backgroundColor: "rgba(0,0,0, 0.5", width: "100%", height: "30%", position: "absolute", left: "0", bottom: "0" }} />
                        <img
                            className='d-block w-100 carou'
                            src={isSmallScreen ? 'images/smallcar2.jpg' :'images/ecommercecar.jpg'}
                            alt='Second slide'
                        />

                        <Carousel.Caption>
                            <h3>eCommerce</h3>
                            <p>Tu tienda en línea!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{border: "solid 7px #F0F0F0" }}>
                        <div style={{ backgroundColor: "rgba(0,0,0, 0.5", width: "100%", height: "30%", position: "absolute", left: "0", bottom: "0" }} />
                        <img
                            className='d-block w-100 carou'
                            src={isSmallScreen ? 'images/smallcar3.jpg' :'images/brandcar.jpg'}
                            alt='Third slide'
                        />

                        <Carousel.Caption>
                            <h3>Da a conocer tu marca</h3>
                            <p>
                                La página web en donde presentas tus productos
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
}

export default MyCarousel;
