import Nav from 'react-bootstrap/Nav';
import styles from './Nav.module.css'
import { Link } from 'react-router-dom';

function InicioNav() {
  return (
    <Nav
      activeKey="/home"
      style={{ paddingTop: "3dvh", backgroundColor: "#98a6ad", display: "flex", justifyContent: "center" }}
    >
      <Nav.Item>
        <Link to="/" className={styles.navLink} > Inicio     </Link>
      </Nav.Item>
      <Nav.Item>
      <Link to="/curriculum" className={styles.navLink} > Curriculum     </Link>
      </Nav.Item>
      <Nav.Item>
      <Link to="/blog" className={styles.navLink} > Blog     </Link>
      </Nav.Item>
      

    </Nav>
  );
}

export default InicioNav;