import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import Curriculum from './components/Curriculum/Curriculum';
import RootLayout from './components/routes/RootLayout';
import BlogList from './components/Blog/BlogList';
import ContactForm from './components/ContactForm/ContactForm';






const router = createBrowserRouter([
  {
    path: "/", element: <RootLayout />, children: [
      {path: "/", element: <App /> },
      {path: "/curriculum", element: <Curriculum /> },
      {path: "/blog", element: <BlogList/>},
      {path:"/contacto", element: <ContactForm/>},
      {path: "/enviado", element: <App/>},
    ]
  },

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);